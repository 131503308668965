#contactPageDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
#contactItemsDiv {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#contactItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1%;
  margin-right: 1%;
}

#contactItem div {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

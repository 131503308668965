#whoAreWeDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#whoAreWeDiv div {
  margin-top: -5%;
  width: 50%;
  font-size: 20px;
}

#activitiesDiv {
  width: 100%;
  height: 90%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

#activityItemDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  text-align: center;
  margin: 2%;
  border-bottom-style: solid;
  border-bottom-width: 0.1px;
}
#activityItemDiv div {
  margin: 1%;
}

#activityImagesDiv img {
  margin-left: 1%;
  margin-right: 1%;
  width: 40%;
}

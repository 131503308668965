html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

#header {
  width: 100%;
  height: 10%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 0.01px;
  border-bottom-color: rgba(240, 248, 255, 0.185);
  padding-bottom: 5px;
  position: fixed;
}
#header a {
  color: rgb(203, 236, 236);
}

#header img {
  width: 130px;
  height: 100px;
  cursor: pointer;
  margin-left: 1%;
}

#navItems {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#navItems a {
  margin-left: 1%;
  margin-right: 1%;
  text-decoration: none;
}

#footer {
  width: 100%;
  bottom: 0;
  height: 12%;
  text-align: center;
  display: block;
  position: absolute;
}
#footer img {
  width: 50%;
}

#counterDiv {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
}

.mainDiv {
  background-position: center;
  background-size: cover;
  height: 100%;
  overflow-y: hidden;
}

#bookImage {
  z-index: 21;
  position: fixed;
  left: 0;
  margin-top: 18%;
  margin-left: 1%;
  cursor: pointer;
}
